
import React from 'react';
import emailjs from 'emailjs-com';
import { Form, Input, TextArea, Button } from 'semantic-ui-react';
import Swal from 'sweetalert2';

const SERVICE_ID = "service_6clsg9l";
const TEMPLATE_ID = "template_u1d3p2x";
const PUBLIC_KEY = "KMPGD1VZdgnOYCNqV";

const ContactForm = () => {
    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
          .then((result) => {
            console.log(result.text);
            Swal.fire({
              icon: 'success',
              title: 'El mensaje ha sido enviado',
            })
          }, (error) => {
            console.log(error.text);
            Swal.fire({
              icon: 'error',
              title: 'Ooops, algo salió mal',
              text: error.text,
            })
          });
        e.target.reset()
      };
    

    return (
        <div>
            <div className="intro-container">
                <h2>Contacto</h2>
                <p>Si está interesado en cómo transcribir de voz a texto audios más largos en esta y otras lenguas indígenas, no dude en contactar:</p>
            </div>
            <div className="Form">
                <Form onSubmit={handleOnSubmit}>
                    <Form.Field
                        id='form-input-control-email'
                        control={Input}
                        label='Email'
                        name='user_email'
                        placeholder='Email…'
                        required
                        icon='mail'
                        iconPosition='left'
                    />
                    <Form.Field
                        id='form-input-control-last-name'
                        control={Input}
                        label='Nombre'
                        name='user_name'
                        placeholder='Nombre…'
                        required
                        icon='user circle'
                        iconPosition='left'
                    />
                    <Form.Field
                        id='form-textarea-control-opinion'
                        control={TextArea}
                        label='Mensaje'
                        name='user_message'
                        placeholder='Mensaje…'
                        required
                    />
                    <Button type='submit' color='green'>Enviar</Button>
                </Form>
            </div>
        </div>
    );
};

export default ContactForm;