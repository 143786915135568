import React from 'react';


const PorQue = () => {
    return (
        <div className="intro-container">
            <h2>¿Por qué?</h2>
            <h3>Wayuunaiki y su Importancia en la Era Digital</h3>
            <div >
                <p>El Wayuunaiki es la lengua de los Wayuu, un pueblo indígena que habita en la península de La Guajira, compartida entre Colombia y Venezuela. Aproximadamente 400,000 personas se comunican en este idioma.</p> 
                <p>A pesar de su rica herencia cultural y lingüística, <a href="https://news.un.org/es/story/2019/03/1452751">el Wayuunaiki enfrenta amenazas de disminución y asimilación</a>. Por ello, un transcriptor de voz en esta lengua no solo ayuda a preservarla, sino que también facilita su enseñanza e investigación lingüística. La tecnología puede ser un aliado esencial para mantener viva esta lengua, potenciando su presencia en la educación y fortaleciendo su relevancia en la sociedad moderna.</p>
            </div>
        </div>
    );
};

export default PorQue;
