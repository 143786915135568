import React, { useState, useRef, useEffect } from 'react';
import toWav from 'audiobuffer-to-wav';

function AudioRecorder({ onRecordingComplete, reset }) {
  const [isRecording, setIsRecording] = useState(false);
  const [counter, setCounter] = useState(30);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const audioStreamRef = useRef(null);
  const audioContext = useRef(new AudioContext());

  const resampleAudio = async (audioBuffer) => {
    const offlineContext = new OfflineAudioContext(1, audioBuffer.duration * 16000, 16000);
    const source = offlineContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(offlineContext.destination);
    source.start(0);
    const resampledBuffer = await offlineContext.startRendering();
    return resampledBuffer;
  };

  useEffect(() => {
    let timer;
    if (isRecording && counter > 0) {
      timer = setInterval(() => {
        setCounter((currentCounter) => currentCounter - 1);
      }, 1000);
    } else if (counter === 0) {
      stopRecording();
      clearInterval(timer);
    } else {
      clearInterval(timer);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isRecording, counter]);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    audioStreamRef.current = stream;

    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = event => {
      if (event.data.size > 0) {
        recordedChunksRef.current.push(event.data);
      }
    };

    mediaRecorderRef.current.onstop = async () => {
      const originalBlob = new Blob(recordedChunksRef.current);
      const arrayBuffer = await originalBlob.arrayBuffer();
      const audioBuffer = await audioContext.current.decodeAudioData(arrayBuffer);

      const resampledBuffer = await resampleAudio(audioBuffer);
      const wavBlob = new Blob([toWav(resampledBuffer)], { type: 'audio/wav' });

      onRecordingComplete(wavBlob);
      recordedChunksRef.current = []; // Limpiar el array de chunks
      audioStreamRef.current.getTracks().forEach(track => track.stop());
      audioStreamRef.current = null;
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  return (
    <button 
      className={`btn-record ${isRecording ? 'is-recording' : ''}`}
      onClick={isRecording ? stopRecording : startRecording}
    >
      {isRecording ? `Detener grabación...${counter}` : 'Comenzar grabación'}
    </button>
  );
}

export default AudioRecorder;
