import React from 'react';


const MasInfo = () => {
    return (
        <div className="intro-container">
            <h2>Más información</h2>
            <h3>Modelos de reconocimiento de voz para lenguas de bajos recursos</h3>
            <div>
            <p>Desarrollar, entrenar y validar modelos de reconocimiento de voz para lenguas indígenas y de bajos recursos es una labor de vital importancia. Estas actividades no solo contribuyen a la preservación y revitalización de las lenguas, sino que también facilitan su enseñanza e investigación lingüística. Para un país tan diverso como Colombia, donde se hablan numerosas lenguas indígenas, los beneficios son aún más significativos. La tecnología puede ser un aliado esencial para mantener viva la riqueza lingüística del país, potenciando su presencia en la educación y fortaleciendo su relevancia en la sociedad moderna.</p>

                <ul>
                <li><a href="https://orkidea.eu/2023-10-13-preservando-el-wayuunaiki-un-viaje-desde-el-el-vaup%C3%A9s/">Trascribir el wayuunaike: un viaje desde el Vaupés</a></li>
                <li><a href="https://huggingface.co/orkidea/whisper-small-guc">Modelo de reconocimiento de voz entrenado para wayuunaiki</a></li>
                <li><a href="https://lenguascolombiagit-4wappxzhsp6wmkcgnvjpikv.streamlit.app/">Lenguas indigenas de Colombia por número de habtantes</a></li>
                </ul>
                </div>
        </div>
    );
};

export default MasInfo;
