import React, { useState } from 'react';
import './App.css';
import AudioUploader from './components/AudioUploader'; // Importar el componente
import AudioRecorder from './components/AudioRecorder'; // Importar el componente
import AudioTranscriber from './components/AudioTranscriber'; // Importa el componente
import ProgressBar from './components/ProgressBar';
import AlertMessage from './components/AlertMessage';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import NavBar from './components/NavBar';
import PorQue from './pages/porque';
import MasInfo from './pages/masinfo';
import ContactForm from './components/ContactForm';



function App() {
  const [isAudioLoaded, setAudioLoaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [transcription, setTranscriptionText] = useState("");
  const [progress, setProgress] = useState(0);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const url = 'https://lingua.orkiedea.eu';
  const titulo = 'Trascribe de voz a texto en Wayuunaiki';
  const imag = 'https://lingua.orkiedea.eu/logo192.png';
  const description = 'Esta aplicación web permite transcribir de voz a texto en Wayuunaiki';


  const handleAudioFileSelected = (file) => {
    setSelectedFile(file);
    setAudioLoaded(true);
  };
  const handleRecordingComplete = (audioBlob) => {
    setSelectedFile(audioBlob);
    setAudioLoaded(true);
  };

  const resetAudio = () => {
    setSelectedFile(null);
    setAudioLoaded(false);
    setTranscriptionText("");
    setProgress(0);
  };

  const handleAlert = (alertData) => {
    setAlert(alertData);
    // Puedes añadir un timeout para que el mensaje de alerta desaparezca después de un tiempo determinado.
    setTimeout(() => {
      setAlert({ message: '', type: '' });
    }, 3000);
  };

   

    return (
      <div className="app-container">
        <NavBar />
        <h1>Ashajaa wane anüikii wayuunaiki</h1>
        <h2>Transcribe de voz a texto en wayuunaiki</h2>
        <div className="audio-controls">
          <AudioUploader onFileSelected={handleAudioFileSelected} /> {/* Uso del componente */}
          <span>o</span>
          <AudioRecorder onRecordingComplete={handleRecordingComplete} reset={!isAudioLoaded} />
        </div>
        <small>Archivos de audio: Máximo 30 segundos. Formatos:  mp3 y wav</small>
        <div className={isAudioLoaded ? "audio-check audio-loaded" : "audio-check"}>
          {isAudioLoaded && <p>Reproduce el audio y si no estás conforme vuelve a grabar</p>}
          {isAudioLoaded && <audio controls src={URL.createObjectURL(selectedFile)}></audio>}
          {isAudioLoaded &&
            <div className="transcriber-container">
              <div className="button-group">
                <button className="btn-repeat" onClick={resetAudio}>Repetir</button>
                <AudioTranscriber
                  audioBlob={selectedFile}
                  onTranscriptionComplete={(text) => { setTranscriptionText(text) }}
                  setProgress={setProgress}
                  onAlert={handleAlert}
                />

              </div>
              <ProgressBar progress={progress} />
            </div>
          }
          {alert.message && <AlertMessage message={alert.message} type={alert.type} />}
        </div>

        <textarea readOnly value={transcription} className="transcription-area"></textarea>
        <div id="porque"><PorQue /></div> 
        <div id="masinfo"><MasInfo /></div>
        <div id="contactar"><ContactForm  /></div>

        {/*       <div className="validation-controls">
        <button className="btn-validate">Validar</button>
        <button className="btn-correct">Corregir</button>
      </div> */}
        <footer>
          <p>Creado con ❤️ <br /> por <a href="https://col.social/@jp">@col.social@jp</a> </p>

          <p>2023</p>
          <div className='conteiner-social'>

          <div className="social">
            <FacebookShareButton url={url} quote={titulo} imag={imag} description={description} >
            Compartir en Facebook
            </FacebookShareButton>
            </div>
            <div className="social">
            <TwitterShareButton url={url} quote={titulo} imag={imag} description={description}>
              Compartir en Twitter
            </TwitterShareButton>
            </div>

            
          </div>
        </footer>
      </div>
    );
  }

  export default App;
