import React, { useState, useEffect, useCallback } from 'react';

function AudioTranscriber({ 
    audioBlob, 
    onTranscriptionComplete, 
    progress, 
    setProgress, 
    onAlert = (handleAlert) => {} // valor predeterminado para onAlert 
}) {
    const [, setTranscription] = useState("");
    const [isTranscribing, setIsTranscribing] = useState(false);

    const MODEL_DOWNLOAD_SPEED = 74.8 * 1000; // bytes por segundo, es solo un valor de ejemplo
    const AUDIO_PROCESSING_SPEED = 2000;

    const adjustSpeedBySize = useCallback(
      (baseSpeed, blobSize) => {
        if(blobSize > 1000000) {
            return baseSpeed * 1.5;
        }
        return baseSpeed;
      },
      []
    );
  
    const adjustedModelSpeed = adjustSpeedBySize(MODEL_DOWNLOAD_SPEED, audioBlob.size);
    const adjustedAudioProcessingSpeed = adjustSpeedBySize(AUDIO_PROCESSING_SPEED, audioBlob.size)

    useEffect(() => {
        if (isTranscribing) {
          // Estimación de la descarga del modelo (80% del progreso total).
          const modelProgressInterval = setInterval(() => {
            setProgress(prevProgress => Math.min(prevProgress + 8, 80));
          }, 1000);

          setTimeout(() => {
            clearInterval(modelProgressInterval);
            // Estimación del procesamiento de audio (10% del progreso total).
            const audioProgressInterval = setInterval(() => {
                setProgress(prevProgress => {
                    if (prevProgress < 90) {
                        return prevProgress + 1;
                    } else {
                        clearInterval(audioProgressInterval);
                        return prevProgress;
                    }
                });
            }, (audioBlob.size / adjustedAudioProcessingSpeed) * 1000);
          }, (0.9 * audioBlob.size / adjustedModelSpeed) * 1000);

        }
    }, [isTranscribing, audioBlob, adjustedModelSpeed, adjustedAudioProcessingSpeed, setProgress]);

   const transcribeAudio = async () => {
       setIsTranscribing(true);
   
       const formData = new FormData();
       formData.append('audio', audioBlob);
       let response;
       let attempts = 0;
   
       while (attempts < 3) {
           try {
               response = await fetch('https://europe-west3-wayuunaiki-7662e.cloudfunctions.net/transcribe_audio', { 
                   method: 'POST', 
                   body: formData 
               });

               if (!response.ok) {
                throw new Error(`Error del servidor: ${response.statusText}`);
             }
   
               // Si la respuesta es 503, espera 1 segundo antes de intentarlo de nuevo.
               if (response.status === 503) {
                   attempts += 1;
                   await new Promise(r => setTimeout(r, 1000));
               } else {
                   break; // Salimos del ciclo si obtenemos cualquier otra respuesta.
               }
           } catch (error) {
               onAlert({ message: error.message, type: "error" });
               // Detener la barra de progreso en caso de error.
               setProgress(0);
               setIsTranscribing(false);
               return; // Finalizamos la funcion si hay un error en la solicitud.
           }
           
       }
   
       if (attempts === 3 && (!response || response.status === 503)) {
           onAlert({ message: "Problemas de conexión con el servidor. Por favor, inténtalo de nuevo más tarde.", type: "error" });
           setIsTranscribing(false);
   
           // Detener la barra de progreso en caso de problemas de conexión.
           setProgress(prevProgress => prevProgress < 100 ? prevProgress : 100);
           return; // Finalizamos la función si hemos alcanzado el número máximo de intentos.
       }
   
       try {
           // Si la respuesta no es exitosa, lanza un error
           if (!response.ok) {
               throw new Error('Error en la transcripción. Intentalo de nuevo.');
           }
   
           const data = await response.json();
   
           setTranscription(data.transcription);
           onTranscriptionComplete(data.transcription);
   
           // Si la transcripción es exitosa, establece el progreso al 100%.
           setProgress(100);
           onAlert({ message: "Transcripción completada con éxito!", type: "success" });
           setIsTranscribing(false);
       } catch (error) {
           onAlert({ message: error.message, type: "error" });
           
   
           // Detener la barra de progreso en caso de error.
           setProgress(0);
           setIsTranscribing(false);
       }
   };
   

   return (
    <div>
        {/* Deshabilitamos el botón y cambiamos el texto durante la transcripción */}
        <button 
            className={`btn-transcribe ${isTranscribing ? 'transcribing' : ''}`} 
            onClick={transcribeAudio} 
            disabled={isTranscribing}
        >
        {isTranscribing ? 'Transcribiendo...' : 'Transcribir'}
        </button>

    </div>
);
  
}

export default AudioTranscriber;
