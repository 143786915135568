import React from "react";

const NavBar = () => {
    return (
        <nav style={styles.navbar}>
            <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="lingua" style={styles.img}/>
            <a href="#porque" style={styles.link}>¿Por qué?</a>
            <a href="#masinfo" style={styles.link}>Más información</a>
            <a href="#contactar" style={styles.link}>Contacto</a>
        </nav>
    );
};

const styles = {
    navbar: {
        backgroundColor: '#f8f9fa',
        height: '50px',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    link: {
        color: '#212529',
        textDecoration: 'none',
        fontSize: '16px',
    },
    img: { height: '30px', width:'30px' }
}

export default NavBar;
