import React, { useRef } from 'react';
import toWav from 'audiobuffer-to-wav';

function AudioUploader({ onFileSelected }) {
  const fileInputRef = useRef(null);

  const resampleAudio = async (audioBuffer) => {
    const offlineContext = new OfflineAudioContext(1, audioBuffer.duration * 16000, 16000);
    const source = offlineContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(offlineContext.destination);
    source.start(0);
    const resampledBuffer = await offlineContext.startRendering();
    return resampledBuffer;
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const audioContext = new AudioContext();
    const fileReader = new FileReader();

    fileReader.onload = async (e) => {
      const arrayBuffer = e.target.result;
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      const resampledBuffer = await resampleAudio(audioBuffer);
      
      // Convert resampled audio to WAV
      const wavData = toWav(resampledBuffer);
      const audioBlob = new Blob([wavData], { type: 'audio/wav' });
      onFileSelected(audioBlob);  // Passing the WAV blob to parent
    };

    fileReader.readAsArrayBuffer(file);
  };

  return (
    <div className="audio-uploader">
      <button 
        className="btn-upload" 
        onClick={() => fileInputRef.current.click()}
      >
        Subir audio
      </button>
      <input 
        type="file" 
        accept="audio/*"
        onChange={handleFileChange}
        ref={fileInputRef} 
        style={{ display: 'none' }} 
      />
    </div>
  );
}

export default AudioUploader;
